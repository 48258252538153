<h1 mat-dialog-title>
    <span translate>ndf.frais.title</span>
    <span [mat-dialog-close]="dialogCloseResult"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <please-wait [hidden]="!isLoading"></please-wait>
    <form autocomplete="off" [hidden]="isLoading" id="fraisAddForm" #fraisAddForm="ngForm">
        <mat-tab-group #matTabGroup dynamicHeight="dynamicHeight" animationDuration="500ms" (selectedIndexChange)="tabChanged($event)">
            <mat-tab label="{{ 'ndf.frais.tabs.saisieFrais' | translate }}">
                <fieldset *ngIf="!isLoading" [disabled]="!data.canModifier">
                    <div class="row">
                        <!-- Colonne de gauche -->
                        <div class="col-md-8 prevent-drop">
                            <div class="row">
                                <!-- Date -->
                                <custom-input ngDefaultControl floatLabel="always" [rCol]="isShowSaveTrajetCheckbox && isShowSaisieTrajets ? 5 : 6"
											  #date="ngModel"
                                              id="date" name="date"
                                              customType="daterange"
                                              [placeholder]="'ndf.frais.date' | translate"
                                              [ngModel]="data.frais.date"
                                              [(customModel)]="data.frais.date"
                                              [rangeDeb]="data.ndf.od?.depart_le"
                                              [rangeEnd]="data.ndf.od?.retour_le"
                                              [disabled]="!data.canModifier || isIJ"
                                              [required]="true"
                                ></custom-input>

                                <!-- Ville / Pays -->
                                <custom-input ngDefaultControl floatLabel="always" [rCol]="isShowSaveTrajetCheckbox && isShowSaisieTrajets ? 7 : 6"
                                              #geographie="ngModel"
                                              id="geographie" name="geographie"
                                              customType="autocomplete"
                                              autocompleteType="geographie"
                                              optionDisplay="libelle"
                                              [autocompleteFilter]="{ listeTypes: listeTypeGeo,contexte: 'NDF' }"
                                              [placeholder]="'ndf.frais.villePays' | translate"
                                              [(ngModel)]="data.frais.geographie"
                                              [disabled]="!data.canModifier || !typeEntite?.typeEntiteParam?.destinationModifiableNF || isIJ"
                                              [required]="true"
                                              (onChange)="onGeographieChange()"
                                ></custom-input>
                            </div>

                            <div class="row">
                                <!-- Choix de la prestation -->
                                <custom-input ngDefaultControl floatLabel="always" [rCol]="canShowIndemnite() || canShowTournee() ? (isShowSaveTrajetCheckbox && isShowSaisieTrajets ? 5 : 6) : 12"
											  #prestation="ngModel"
                                              id="prestation" name="prestation"
                                              customType="autocomplete"
                                              autocompleteType="prestationFrais"
                                              [autocompleteFilter]="{ idPays: data.frais.pays?.idPays || data.frais?.geographie?.pays?.idPays,idTypeEntite: data.ndf.typeEntite.idTypeEntite,typeObjet: TypePortee.NF,idObjet: data.ndf.idNDF }"
                                              [placeholder]="''"
                                              [autocompleteLabel]="'ndf.frais.prestation' | translate"
                                              [matLabelHtml]="matLabelHtmlPrestation"
                                              [(ngModel)]="data.frais.prestation"
                                              optionDisplay="libelle"
                                              [disabled]="!data.canModifier || isIJ"
                                              [required]="true"
                                              (onChange)="onPrestationChange()"
                                              [fieldTooltipNio]="{content: TooltipPrestationComponent, options: {
                                                context: { prestation: data.frais.prestation },
                                                position: 'bottom',
                                                offset: (!data.canModifier || isIJ) ? 10 : -10,
                                                width: 'hostNoPadding',
                                                isDisabled: !data.frais.prestation || prestation.invalid
                                              }}"
                                              [matError]="matErrorPrestation"
                                ></custom-input>

                                <ng-template #matLabelHtmlPrestation>
                                    <span id="wrapper-label-prestation" class="font-weight-normal r-flex align-items-center">
                                        <ng-container *ngIf="data.frais.prestation
                                                && data.frais.prestation.type !== undefined
                                                && (data.frais.prestation.type == TypePrestation.REEL || data.frais.prestation.idPrestation == saisieState?.tauxPrestation?.idPrestation)"
                                        >&nbsp;
                                            <span *ngIf="data.frais.prestation.typeBareme !== TypeBareme.BAREME_SNCF"
                                                  [innerHTML]="'&#8211; ' + (('ndf.prestation.typeRemboursement.' + TypePrestation[data.frais.prestation.type]) | translate:{ montant: (getTauxForPrestation() | montant:this.data.frais.operation?.devise?.code), unite: data.frais.prestation.unite?.libelle })">
                                            </span>
                                            <span *ngIf="data.frais.prestation.typeBareme === TypeBareme.BAREME_SNCF">
                                                &#8211; {{ 'ndf.frais.baremeSNCF' | translate }}
                                            </span>
                                        </ng-container>
                                        <mat-icon *ngIf="!!cumulRestant" class="material-icons-outlined" [nioTooltip]="'ndf.prestation.plafondCumule' | translate: { montant: cumulRestant }">info</mat-icon>
                                    </span>
                                </ng-template>

                                <!-- Sélection de l'indemnité le cas échéant -->
                                <div *ngIf="canShowIndemnite()" class="col-md-6">
                                    <mat-form-field class="d-flex" [floatLabel]="'always'">
                                        <mat-label>{{ 'ndf.frais.indemnite' | translate }}</mat-label>
                                        <mat-select #selectTaux *ngIf="!isIJ" name="taux" [(ngModel)]="data.frais.taux" [compareWith]="fraisService.compareTaux" [disabled]="!data.canModifier"
                                                    [nioTooltip]="tooltipTaux" [nioTooltipContext]="{ taux: findTaux(data.frais.taux?.idTaux) }" [nioTooltipDisabled]="!data.frais.taux" nioTooltipPosition="bottom" nioTooltipWidth="host" [nioTooltipOffset]="12">
                                            <mat-option *ngIf="!saisieState?.tauxPrestation?.allTaux?.length" [value]="null">{{ 'od.frais.frais.creation.fraisPrev.aucuneIndemnite' | translate }}</mat-option>
                                            <mat-option [value]="fraisService.prestTauxToFraisMission(taux)" *ngFor="let taux of saisieState?.tauxPrestation?.allTaux"
                                                        [nioTooltip]="tooltipTaux" [nioTooltipContext]="{ taux: taux }" nioTooltipPosition="right"
                                                        [nioTooltipDisabled]="!selectTaux.panelOpen">
                                                {{ taux.libelleSpecial != null &&  taux.libelleSpecial != '' ?  taux.libelleSpecial : taux.libelleTerritoire }}
                                            </mat-option>
                                        </mat-select>
                                        <input *ngIf="isIJ" matInput name="tauxIJ"
                                               [value]="indemniteIJ?.libelleTerritoire + (indemniteIJ?.libelleSpecial ? ' (' + indemniteIJ?.libelleSpecial + ')' : '')"
                                               [nioTooltip]="tooltipTaux" [nioTooltipContext]="{ taux: indemniteIJ }" nioTooltipPosition="bottom" nioTooltipWidth="host" [nioTooltipOffset]="12"
                                               [disabled]="true">
                                    </mat-form-field>
                                </div>

                                <!-- Tournées -->
                                <div id="wrapper-button-tournee" class="col-md-6" *ngIf="canShowTournee()">
                                    <div class="r-flex align-items-center">
                                        <mat-form-field class="d-flex overflow-hidden" appearance="none" floatLabel="always">
                                            <mat-label>{{ 'ndf.frais.tournee' | translate }}</mat-label>
                                            <!-- Input invisible présent uniquement pour faire le label flottant du mat-form-field -->
                                            <input matInput class="cdk-visually-hidden" name="dummy" />

                                            <!-- Tournée création-->
                                            <button *ngIf="data.frais.tournee == null; else tplTourneeSaisie;" mat-button color="primary" (click)="openPopinTournee()">
                                                <span translate>ndf.frais.cliqueTournee</span>&nbsp;
                                                <mat-icon color="primary">search_outline</mat-icon>
                                            </button>

                                            <!--Affiche de l'input tournée-->
                                            <ng-template #tplTourneeSaisie>
                                                <button mat-button color="primary" (click)="openPopinTournee()">
                                                    <span>{{ ('ndf.frais.etapeRenseigne.'+(data.frais.tournee?.listeEtapes?.length > 1 ? 'plural' : 'one')) | translate:{ count: data.frais.tournee?.listeEtapes?.length } }}</span>&nbsp;
                                                    <mat-icon color="primary">search_outline</mat-icon>
                                                </button>
                                            </ng-template>
                                        </mat-form-field>
                                        <div class="flex-grow-1">
                                            <mat-icon *ngIf="data.frais.tournee == null" class="m-0 material-icons-outlined" [nioTooltip]="'ndf.frais.tooltipTournee' | translate">info</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Prestation avec participants le cas échéant -->
                            <div *ngIf="data.frais.prestation?.genre == Genre.RECEPTION" class="row">
                                <!-- Invités -->
                                <div class="col-md-6" [ngClass]="{ 'has-error': !isInvitesValid() }">
                                    <mat-form-field class="d-flex" appearance="none" floatLabel="always">
                                        <mat-label>{{ 'ndf.frais.invites' | translate }}</mat-label>

                                        <div class="r-flex align-items-baseline" style="line-height: 24px;">
                                            <span class="mr-1" *ngIf="data.frais.prestation?.modeParticipant != ModeParticipants.EXTERNES">{{ 'ndf.frais.invite.nbInternes.'+(countInvitesForType(TypeParticipant.INTERNE) > 1 ? 'plural' : 'one') | translate:{ count: countInvitesForType(TypeParticipant.INTERNE) } }}</span>
                                            <div class="r-flex align-items-center" *ngIf="data.settings.isReferentielParticipantsExternes;else noRefExterne">
                                                <span *ngIf="data.frais.prestation?.modeParticipant != ModeParticipants.INTERNES">{{ 'ndf.frais.invite.nbExternes.'+(countInvitesForType(TypeParticipant.EXTERNE) > 1 ? 'plural' : 'one') | translate:{ count: countInvitesForType(TypeParticipant.EXTERNE) } }}</span>
                                                <mat-icon class="clickable align-middle ml-1" style="font-size: 1.5em;" (click)="showListeParticipants()">search</mat-icon>
                                            </div>
                                            <ng-template #noRefExterne>
                                                <span><mat-icon class="clickable align-middle" style="font-size: 1.5em;" (click)="showListeParticipants()">search</mat-icon></span>
                                                <div class="r-flex pl-4">
                                                    <mat-form-field class="d-flex" id="wrapper-input-invites-externes" floatLabel="never">
                                                        <input name="nbExternes" matInput #nbExternes="ngModel" [(ngModel)]="data.frais.nbInvites" nNumber [min]="0" [disabled]="!data.canModifier || isIJ" />
                                                        <span class="ml-2" matSuffix>{{ 'ndf.frais.invite.externes.'+(data.frais.nbInvites > 1 ? 'plural' : 'one') | translate }}</span>
                                                    </mat-form-field>
                                                </div>
                                            </ng-template>
                                        </div>

                                        <!-- Input invisible présent uniquement pour faire le label flottant du mat-form-field -->
                                        <input matInput class="cdk-visually-hidden" name="dummy" />
                                    </mat-form-field>
                                </div>

                                <custom-input *ngIf="data.frais.prestation?.participation"
                                              ngDefaultControl floatLabel="always"
                                              rCol="6"
                                              id="isCollaborateurAbsent" name="isCollaborateurAbsent"
                                              customType="checkbox"
                                              [placeholder]="'ndf.frais.participation' | translate"
                                              postLibelle="ndf.frais.participationInformation"
                                              [ngModel]="data.frais.collaborateurAbsent"
                                              [(customModel)]="data.frais.collaborateurAbsent"
                                              [disabled]="!data.canModifier || isIJ"
                                ></custom-input>
                            </div>

                            <div class="row" *ngIf="isShowSaisieTrajets">
                                <div [ngClass]="isShowSaveTrajetCheckbox ? 'col-md-5' : 'col-md-6'">
                                    <adresse name="depart" #depart="ngModel" [(ngModel)]="data.frais.localisationDepart" (onSelect)="onDepartArriveeChange($event)"
                                             floatLabel="always" [placeholder]="'ndf.frais.depart' | translate"
                                             required [disabled]="!data.canModifier || isIJ" [isSelectedValueRequired]="true"
                                    ></adresse>
                                </div>
                                <div [ngClass]="isShowSaveTrajetCheckbox ? 'col-md-5' : 'col-md-6'">
                                    <adresse name="arrivee" #arrivee="ngModel" [(ngModel)]="data.frais.localisationArrivee" (onSelect)="onDepartArriveeChange($event)"
                                             floatLabel="always" [placeholder]="'ndf.frais.arrivee' | translate"
                                             required [disabled]="!data.canModifier || isIJ" [isSelectedValueRequired]="true"
                                    ></adresse>
                                </div>

                                <custom-input *ngIf="isShowSaveTrajetCheckbox"
                                              ngDefaultControl floatLabel="always"
                                              rCol="2"
                                              id="isRetenirTrajet" name="isRetenirTrajet"
                                              customType="checkbox"
                                              [placeholder]="'ndf.frais.retenirTrajet' | translate"
                                              [ngModel]="isRetenirTrajet"
                                              [(customModel)]="isRetenirTrajet"
                                              [disabled]="!data.canModifier || isIJ"
                                ></custom-input>
                            </div>

                            <div class="row">
                                <custom-input *ngIf="(data.frais.prestation?.geolocalisation && !canShowTournee()) || (canShowTournee() && data.frais.tournee == null)"
                                              ngDefaultControl floatLabel="always"
                                              rCol="2"
                                              id="isAllerRetour" name="isAllerRetour"
                                              customType="checkbox"
                                              [placeholder]="'ndf.frais.allerRetour' | translate"
                                              [ngModel]="data.frais.allerRetour"
                                              [(customModel)]="data.frais.allerRetour"
                                              (customModelChange)="onAllerRetourChange($event)"
                                              [disabled]="!data.canModifier || isIJ"
                                ></custom-input>

                                <!-- Quantité -->
                                <custom-input ngDefaultControl floatLabel="always"
                                              [rCol]="((data.frais.prestation?.geolocalisation && !canShowTournee()) || (canShowTournee() && data.frais.tournee == null)) ? (canShowRetenirTrajet && isShowTrajets() && data.frais.prestation.saveTrajet ? 3 : 4) : 6"
                                              id="quantite" name="quantite"
                                              customType="number"
                                              nbDecimales="2"
                                              [placeholder]="'ndf.frais.quantite' | translate"
                                              [suffix]="data.frais.prestation?.unite?.libelle"
                                              [(ngModel)]="data.frais.quantite"
                                              [required]="data.frais.prestation?.quantiteObligatoire"
                                              [disabled]=" !data.canModifier || !isQuantiteModifiable()"
                                              (onChange)="onQuantiteChange()"
                                ></custom-input>

                                <!-- Montant (hors forfait) -->
                                <ng-container *ngIf="data.frais.prestation?.type != TypePrestation.FORFAIT || data.frais.prestation?.idAppTva > 0; else tplMontantForfait;">
                                    <custom-input ngDefaultControl floatLabel="always" rCol="6"
                                                  id="montant" name="montant"
                                                  customType="montant"
                                                  nbDecimales="2"
                                                  min="0"
                                                  [(ngModel)]="data.frais.montant"
                                                  [placeholder]="(data.frais.saisieTaxe && data.frais.isJustificatif ? 'ndf.frais.montantTTC' : 'ndf.frais.montant') | translate"
                                                  [montantDevise]="data.frais.operation.devise?.code"
                                                  [listeDevise]="data.settings.listeDevises"
                                                  [postTooltipNio]="data.frais.abattement != null && data.frais.abattement > 0 ? {
                                                          content: 'ndf.frais.abattement' | translate: { montant: data.frais.abattement },
                                                          icon: 'error_outline'
                                                      } : null"
                                                  [required]="true"
                                                  [disabled]="!data.canModifier"
                                                  (onDeviseChange)="onDeviseChange($event)"
                                    ></custom-input>
                                </ng-container>
                                <!-- Montant (forfait) -->
                                <ng-template #tplMontantForfait>
                                    <custom-input ngDefaultControl floatLabel="always" [lCol]="data.frais.prestation?.bareme ? 3 : 4"
                                                  id="montantForfait" name="montantForfait"
                                                  customType="montant"
                                                  nbDecimales="2"
                                                  min="0"
                                                  [ngModel]="getMontantForfait()"
                                                  [placeholder]="(data.frais.saisieTaxe && data.frais.isJustificatif ? 'ndf.frais.montantTTC' : 'ndf.frais.montant') | translate"
                                                  [montantDevise]="data.frais.operation.devise?.code"
                                                  [listeDevise]="(data.frais.prestation?.bareme || !data.canModifier || isIJ) ? null : data.settings.listeDevises"
                                                  [postTooltipNio]="data.frais.abattement != null && data.frais.abattement > 0 ? {
                                                          content: 'ndf.frais.abattement' | translate: {montant: data.frais.abattement},
                                                          icon: 'error_outline'
                                                      } : null"
                                                  disabled="true"
                                                  (onDeviseChange)="onDeviseChange($event)"
                                    ></custom-input>
                                    <!-- IK -->
                                    <div class="col-md-1 p-0" *ngIf="data.frais.prestation?.bareme">
                                        <mat-icon class="mt-3" *ngIf="!data.frais.idDepense || !data.frais.montant" [nioTooltip]="'ndf.frais.info.montantCalculePostEnregistrement' | translate">info_outline</mat-icon>
                                        <a (click)="viewDetailCalculIk()" *ngIf="data.frais.idDepense && data.frais.montant && data.frais.prestation.typeBareme === TypeBareme.BAREME_FISCAL" [nioTooltip]="'ndf.frais.info.viewDetailCalculIk' | translate">
                                            <mat-icon class="mt-3 c-primary">search_outline</mat-icon>
                                        </a>
                                    </div>
                                </ng-template>
                            </div>

                            <!-- Champs supplémentaires de la prestation -->
                            <div class="row" *ngIf="!!prestation?.model?.libelleField1 || !!prestation?.model?.libelleField2">
                                <custom-input *ngIf="prestation?.model?.libelleField1"
                                              ngDefaultControl floatLabel="always" rCol="6"
											  #field1="ngModel"
                                              id="libelleField1" name="libelleField1"
                                              customType="input"
                                              [placeholder]="prestation?.model?.libelleField1"
                                              [(ngModel)]="data.frais.field1"
                                              [required]="prestation.model?.field1Obligatoire"
                                              [disabled]="!data.canModifier"
                                ></custom-input>
                                <custom-input *ngIf="prestation?.model?.libelleField2"
                                              ngDefaultControl floatLabel="always" rCol="6"
											  #field2="ngModel"
                                              id="libelleField2" name="libelleField2"
                                              customType="input"
                                              [placeholder]="prestation?.model?.libelleField2"
                                              [(ngModel)]="data.frais.field2"
                                              [required]="prestation.model?.field2Obligatoire"
                                              [disabled]="!data.canModifier"
                                ></custom-input>
                            </div>

                            <!-- Zones utilisateurs-->
                            <div class="row">
                                <div class="col-md-12">
                                    <zone-utilisateur #zu="zu" [listeZus]="data.frais.listeZU" [listeZuSaisies]="data.frais.listeZUSaisies"
                                                      [readonly]="!data.canModifier" [canListeZuChange]="true" floatLabel="always">
                                    </zone-utilisateur>
                                </div>
                            </div>

                            <!-- Remarque -->
                            <div class="row">
                                <custom-input ngDefaultControl floatLabel="always" rCol="12" class="col-md-12 px-0"
											  #remarque="ngModel"
                                              id="remarque" name="remarque"
                                              customType="textarea"
                                              [(ngModel)]="data.frais.commentaire"
                                              [placeholder]="prestation?.model?.libelleRemarque || ('ndf.frais.remarque' | translate)"
                                              [required]="prestation?.model?.remarqueObligatoire || isFraisHorsDateOM()"
                                              [postTooltip]="isFraisHorsDateOM() ? ((data.ndf.omPermanent ? 'ndf.frais.erreur.omPermanentDate' : 'ndf.frais.erreur.omDate') | translate) : null"
                                              [disabled]="!data.canModifier"
                                ></custom-input>
                            </div>
                        </div>

                        <!-- Colonne de droite -->
                        <div class="col-md-4 d-flex">
                            <div class="row prevent-drop">
                                <div class="col-md-4">
                                    <div class="r-flex" [class.has-error]="!isJustifValid()">
                                        <mat-form-field appearance="none" floatLabel="always" class="mat-form-field-checkbox" [class]="!isJustifValid() ? 'ng-invalid mat-form-field-invalid' : ''">
                                            <mat-label>{{ 'ndf.frais.justificatif' | translate }}</mat-label>
                                            <mat-checkbox #isJustificatif="ngModel" ngDefaultControl color="primary" id="isJustificatif" name="isJustificatif"
                                                          [(ngModel)]="data.frais.isJustificatif"
                                                          (change)="onJustificatifChange($event.checked)"
                                                          [disabled]="!data.canModifier || (data.frais.prestation?.type == TypePrestation.FORFAIT && !data.frais.prestation?.justificatifObligatoire && !data.frais.prestation?.justificatifDefaut)"
                                                          [nioTooltip]="'ndf.frais.presenceJustificatif' | translate"
                                            ></mat-checkbox>
                                            <span class="ml-1 text-nowrap">{{ 'ndf.frais.numero' | translate: { numero: data.frais.justificatif.numJustificatif != null ? data.frais.justificatif.numJustificatif : ''} }}</span>
                                            <mat-icon *ngIf="!isPJValid()" class="ml-1 error" [nioTooltip]="'ndf.frais.pjObligatoire' | translate">error_outline</mat-icon>
                                            <!-- Input invisible présent uniquement pour faire le label flottant du mat-form-field -->
                                            <input matInput class="cdk-visually-hidden" name="dummy" />
                                        </mat-form-field>
                                    </div>
                                </div>
                                <custom-input ngDefaultControl floatLabel="always" rCol="8"
											  #enseigne="ngModel"
                                              id="enseigne" name="enseigne"
                                              customType="input"
                                              [placeholder]="prestation?.model?.libelleJustificatif || ('ndf.frais.enseigne' | translate)"
                                              [(ngModel)]="data.frais.justificatif.libelle"
                                              [disabled]="!data.canModifier || !data.frais.isJustificatif"
                                              [required]="prestation.model?.libelleJustificatifObligatoire"
                                ></custom-input>
                            </div>
                            <div class="row flex-grow-1" [class.no-document]="!this.data.frais.nbPJ" [class.has-error]="!isPJValid()">
                                <ng-container *ngIf="data.canModifier || this.data.frais.nbPJ > 0; else noDocIcon;">
                                    <div class="col-md-12 mb-3 position-relative">
                                        <document-dropper #documentDropper
                                                           *ngIf="isShowUploader || getNbDocuments() === 0"
                                                           class="document-uploader"
                                                           [source]="'saisie-frais'"
                                                           [settings]="this.data.settings"
                                                           [idCollaborateur]="this.data.ndf.idUser"
                                                           [idObjet]="isFrais() ? this.data.frais.idFrais : this.data.frais.idDepense"
                                                           [context]="isFrais() ? ContextDocument.FRAIS : ContextDocument.NDF_V"
                                                           (onAllDocumentUploaded)="onAllDocumentUploadedFromPreview($event)"
                                                           (onHide)="isShowUploader = false"
                                        ></document-dropper>

                                        <document-viewer *ngIf="getNbDocuments() > 0" [context]="isFrais() ? ContextDocument.FRAIS : ContextDocument.NDF_V" [listeDocuments]="data.frais.listeDocuments" [isAlignRight]="true" [isShowBullets]="true"></document-viewer>
                                    </div>
                                </ng-container>
                                <ng-template #noDocIcon>
                                    <i class="material-icons-outlined">hide_image</i>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </mat-tab>

            <!-- Onglet Mes Frais -->
             <mat-tab *ngIf="isCreation()" label="{{ 'ndf.frais.tabs.mesFrais' | translate }}">
                 <ndf-frais-add-frais [idNDF]="this.data.ndf.idNDF" (onListeEmpty)="onListeEmpty($event)"></ndf-frais-add-frais>
             </mat-tab>

            <!-- Onglet trajets -->
            <mat-tab *ngIf="isShowTrajets()" label="{{ 'ndf.frais.tabs.trajets' | translate }}">
                <ndf-frais-add-trajet (selectTrajet)="selectTrajet($event)" [idPrest]="data.frais.prestation.idPrestation"></ndf-frais-add-trajet>
            </mat-tab>

             <!-- Onglet Pièces jointes -->
             <mat-tab label="{{ 'ndf.frais.tabs.piecesJointes' | translate }}">
                <document-list #listeDocument 
                    [idObjet]="isFrais() ? data.frais.idFrais : data.frais.idDepense" 
                    [idObjetForPJ]="isFrais() ? data.frais.idFrais : (data.frais.idDepense || 0)" 
                    [idCollaborateur]="data.ndf.idUser"
                    [canAddDocument]="data.canModifier"
                    [context]="isFrais() ? ContextDocument.FRAIS : ContextDocument.NDF_V"
                    [isUploadToPending]="isCreation()"
                    [settings]="data.settings"
                    [errorAlreadyUsedMessage]="'ndf.frais.erreur.errorAlreadyUsedMessage'"
                    [canDeleteDocument]="data.canModifier && data.frais.idDepense > 0"
                    (onDocumentRemoved)="onDocumentRemovedFromList($event)"
                    (onDocumentUploaded)="onDocumentUploadedFromList($event)"
                    [refreshList$]="refreshListPj$">
                </document-list>
             </mat-tab>
        </mat-tab-group>
    </form>
</div>
<div mat-dialog-actions *ngIf="!isLoading">
    <ng-container *ngIf="data.canModifier">
        <button mat-stroked-button color="warn" (click)="deleteDepense()" [disabled]="isProcessing" *ngIf="data.frais.idDepense || data.frais.idFrais">
            <span *ngIf="!isDeleting" translate>global.actions.supprimer</span>
            <mat-spinner class="m-5" diameter="28" *ngIf="isDeleting"></mat-spinner>
        </button>
        <!-- Bouton d'enregistrement de l'onglet saisie de frais  -->
        <button *ngIf="!canAjouterNote()" mat-flat-button color="primary" (click)="onSave()"
                [disabled]="isProcessing || fraisAddForm.invalid || !isTaxeValid() || !isJustifValid() || !isPJValid() || !isInvitesValid() || !isGeographieValid() || (hasNotAutorisationCirculer() && data.settings.niveauControleAutorisationVehiculeNdf === niveauAutorisation.BLOQUANT)">
            <span *ngIf="!isSaving" translate>global.actions.enregistrer</span>
            <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
        </button>
        <!-- Bouton de validation pour l'onglet mes frais -->
        <button *ngIf="canAjouterNote()" mat-flat-button color="primary" [disabled]="isProcessing || isListeEmpty" (click)="selectMesFrais()">
            <span *ngIf="!isAddingToNote" translate>ndf.frais.ajouterALaNote</span>
            <mat-spinner class="m-5" diameter="28" *ngIf="isAddingToNote"></mat-spinner>
        </button>
    </ng-container>

    <!-- Bouton de rejet de frais -->
    <button *ngIf="data.ndf.canRejeter() && data.settings.isFraisRefuses && !data.frais.rejete" [disabled]="isProcessing" mat-stroked-button color="warn" (click)="rejeterFrais()">
        <span *ngIf="!isRejecting" translate>ndf.frais.rejeterFrais</span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isRejecting"></mat-spinner>
    </button>
</div>

<!-- Tooltip d'information sur le taux spécial survolé -->
<ng-template #tooltipTaux let-taux="taux">
    <table>
        <tr *ngIf="taux.libelleSpecial">
            <td><label translate>frais.tooltipTaux.libelle</label></td>
            <td>{{ taux.libelleSpecial }}</td>
        </tr>
        <tr *ngIf="taux.libelleTerritoire">
            <td><label translate>frais.tooltipTaux.lieu</label></td>
            <td>{{ taux.libelleTerritoire }}</td>
        </tr>
        <tr>
            <td><label translate>frais.tooltipTaux.periode</label></td>
            <td>{{ fraisService.getPeriode(taux) }}</td>
        </tr>
        <tr>
            <td><label translate>frais.tooltipTaux.montant</label></td>
            <td>{{ taux.montant | montant:taux.devise }}</td>
        </tr>
    </table>
</ng-template>
